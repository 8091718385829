<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <tiempo-form
          :id="null"
          :idempleado="usuarioIdempleado"
          :geolocalizacion="geolocalizacion"
          :por-cronometro="true"
          :es-desplazamiento="cronometroEsDesplazamiento"
          :finicio="cronometroFinicioToDateTime"
          :has-perm-introducir-tiempos-mas-alla-fecha-actual="hasViewPerm(permissions.tiempos.introducirTiemposMasAllaFechaActual)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import TiempoForm from '../components/TiempoForm'
import Data from '../components/TiempoAddData'
import { get, sync } from 'vuex-pathify'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { currentDateTime, toDateTime, diff as diffDate, currentDate, parseHHmm } from '@/utils/date'

export default {
  components: {
    TiempoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
    }
  },
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
    ...sync(
      'cronometro',
      {
        cronometroFinicio: 'finicio',
        cronometroIdparte: 'idparte',
        cronometroLatitud: 'latitud',
        cronometroLongitud: 'longitud',
        cronometroPara: 'para',
        cronometroEsDesplazamiento: 'esDesplazamiento',
      }
    ),
    usuarioIdtecnico: get('usuario/idtecnico'),
    cronometroFinicioToDateTime () {
      return toDateTime(this.cronometroFinicio)
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Detener el cronómetro'
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
      // alert de tiempo trabajado
      const trabajado = (this.$options.filters.humanizeDuration(
        diffDate(currentDateTime(), toDateTime(this.cronometroFinicio))
      ))
      this.$alert.showSnackbarInfo(`Llevas trabajado ${trabajado}`)
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        const ffin = parseHHmm(this.formData.hora_fin, currentDate())
        await Data.insert(this, this.formData, this.usuarioIdempleado, ffin)
        this.cronometroIdparte = null
        this.cronometroFinicio = null
        this.cronometroLatitud = null
        this.cronometroLongitud = null
        this.cronometroPara = null
        this.cronometroEsDesplazamiento = false
        this.$appRouter.go(-1)
      }
    },
  }
}
</script>